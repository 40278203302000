import { scrollLock } from "./../utility";

export default function menu() {
  const header = document.querySelector("#js-header");
  const toggle = document.querySelector("#js-menu-toggle");
  const IS_ACTIVE = "is-active";

  if (toggle) {
    toggle.addEventListener("click", (e) => {
      e.preventDefault();

      if (toggle.classList.contains(IS_ACTIVE)) {
        header.classList.remove(IS_ACTIVE);
        toggle.classList.remove(IS_ACTIVE);
        scrollLock(false);
      } else {
        header.classList.add(IS_ACTIVE);
        toggle.classList.add(IS_ACTIVE);
        scrollLock();
      }
    });
  }
}

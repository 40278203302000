import "intersection-observer";

export default function graphAnimation() {
  const animateCounter = (barInfo) => {
    const percent = parseFloat(barInfo.dataset.total);
    const percentElement = barInfo.querySelector(".graph-percent");
    let counter = 0;

    const animation = setInterval(() => {
      if (counter >= percent) {
        clearInterval(animation);
      } else {
        counter += 1;
        percentElement.style.width = `${counter}%`;
      }
    }, 15);
  };

  const initializeBars = (barInfos) => {
    barInfos.forEach((barInfo) => {
      const barPercent = barInfo.querySelector(".graph-percent");
      barPercent.style.width = "0%";
    });
  };

  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const barInfo = entry.target;
        animateCounter(barInfo);
        observer.unobserve(barInfo);
      }
    });
  };

  const observer = new IntersectionObserver(handleIntersection, {
    threshold: 0.5,
  });
  const barInfos = document.querySelectorAll(".graph-wrapper");
  initializeBars(barInfos);
  barInfos.forEach((barInfo) => observer.observe(barInfo));
}

export default function sceneIframe() {
  const sceneButtons = document.querySelectorAll(".scene-button");
  const sceneIframe = document.getElementById("sceneIframe");
  const IS_ACTIVE = "is-active";

  sceneButtons.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();

      // Remove is-active class from all buttons
      sceneButtons.forEach((btn) => btn.classList.remove(IS_ACTIVE));

      // Add is-active class to the clicked button
      button.classList.add(IS_ACTIVE);

      // Set the iframe source
      const iframeSrc = button.getAttribute("data-iframe-src");
      console.log(iframeSrc)
      sceneIframe.src = iframeSrc;
    });
  });
}

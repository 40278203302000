import {gsap, ScrollTrigger} from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export default function animation() {
  document.fonts.ready.then(function() {
  // window.addEventListener('load', () => {

    // Top page
    const top = document.querySelector('.l-index')
    if (top) {
      // MV area
      leftToRightForInset('.top-mv-title', '.top-mv-title', 0, 1)
      fadeIn('.top-mv-image', '.top-mv-image', 0.4, 2)
      fadeIn('.top-mv-desc', '.top-mv-desc', 0.2, 2)

      leftToRightForPolygon('.top-why-title', '.top-why-title', 0, 1)
      fadeIn('.top-why-desc', '.top-why-desc', 0.6, 1.2)
      fadeIn('.top-why-image-inner', '.top-why-image-inner', 0.6, 1.2)


      fadeIn('.top-why-slogan', '.top-why-slogan', 0.6, 1.2)
      leftToRightForInset('.top-why-banner', '.top-why-banner', 0, 1, {
        to: {
          onComplete: function() {
            // アニメーションが完了した後に実行される関数
            gsap.set(".top-why-banner", {clipPath: "", opacity: ""});
          }
        }
      })

      fadeIn('.service-heading', '.service-heading', 0, 1, {
        from: {
          rotationY: 45,
        },
        to: {
          rotationY: 0
        }
      })
      leftToRightForInset('.l-index-service-efo .service-heading-wrapper', '.l-index-service-efo .service-heading-wrapper', 0.4, 1)
      fadeIn('.l-index-service-efo .service-container', '.l-index-service-efo .service-container', 0.4, 1.2)

      leftToRightForInset('.l-index-service-com .service-heading-wrapper', '.l-index-service-com .service-heading-wrapper', 0.4, 1)
      fadeIn('.l-index-service-com .service-container', '.l-index-service-com .service-container', 0.4, 1.2)

      leftToRightForInset('.optimization-heading-wrapper', '.optimization-heading-wrapper', 0.4, 1)
      fadeIn('.optimization-container', '.optimization-container', 0.4, 1.2)

      fadeIn('.support-title', '.support-title', 0, 1)
      fadeIn('.support-info', '.support-info', 0, 1.5, {
        from: {
          rotationY: 90,
        },
        to: {
          rotationY: 0
        }
      })
      fadeIn('.support-desc', '.support-desc', 0, 1.5)

      leftToRightForInset('.flow', '.flow', 0, 1.2)

      fadeIn('.achievement-title-wrapper', '.achievement-title-wrapper', 0, 2)
      fadeIn('.achievement-content', '.achievement-content', 0.3, 2)
      fadeIn('.achievement-image', '.achievement-image', 0.6, 2)

      fadeIn('.card-list', '.card-list', 0, 2)
      fadeIn('.topics', '.topics', 0, 2)
    }
  })

  // com page
  const com = document.querySelector('.l-com')
  if (com) {
    leftToRightForInset('.mv-title', '.mv-title', 0, 1)
    fadeIn('.mv-desc', '.mv-desc', 0.2, 1.2)
    fadeIn('.mv-image', '.mv-image', 0.4, 1.2)

    leftToRightForPolygon('.why-title', '.why-title', 0, 1)
    fadeIn('.why-item', '.why-item', 0.4, 1.2)
    fadeIn('.why-card-image-wrapper', '.why-card-image-wrapper', 0.6, 1.2)
    leftToRightForInset('.why-banner ', '.why-banner ', 0, 1)

    fadeIn('.merit-title', '.merit-title', 0, 1, {
      from: {
        rotationY: 90,
      },
      to: {
        rotationY: 0
      }
    })
    fadeIn('.merit-item:first-child', '.merit-item:first-child', 0.2, 1.2)
    fadeIn('.merit-item:nth-child(2)', '.merit-item:nth-child(2)', 0.2, 1.2)
    fadeIn('.merit-item:nth-child(3)', '.merit-item:nth-child(3)', 0.2, 1.2)

    leftToRightForInset('.function-title', '.function-title', 0, 1)

    const rotateY = {
      from: {
        rotationY: 90,
      },
      to: {
        rotationY: 0
      }
    }
    fadeIn('.function-item:first-child', '.function-item:first-child', 0.2, 1, rotateY)
    fadeIn('.function-item:nth-child(2)', '.function-item:nth-child(2)', 0.3, 1, rotateY)
    fadeIn('.function-item:nth-child(3)', '.function-item:nth-child(3)', 0.4, 1, rotateY)
    fadeIn('.function-item:nth-child(4)', '.function-item:nth-child(4)', 0.5, 1, rotateY)
    fadeIn('.function-item:nth-child(5)', '.function-item:nth-child(5)', 0.6, 1, rotateY)

    leftToRightForInset('.l-com-function-automation-title', '.l-com-function-automation-title', 0.4, 1)
    fadeIn('.function-card-list-automation .function-card-list-item:first-child', '.function-card-list-automation .function-card-list-item:first-child', 0.2, 0.5)
    fadeIn('.function-card-list-automation .function-card-list-item:nth-child(2)', '.function-card-list-automation .function-card-list-item:nth-child(2)', 0.4, 0.5)

    leftToRightForInset('.l-com-function-support-title', '.l-com-function-support-title', 0.4, 1)
    fadeIn('.l-com-function-support-cards .function-card-list-item:first-child', '.l-com-function-support-cards .function-card-list-item:first-child', 0.2, 0.5)
    fadeIn('.l-com-function-support-cards .function-card-list-item:nth-child(2)', '.l-com-function-support-cards .function-card-list-item:nth-child(2)', 0.4, 0.5)
    fadeIn('.l-com-function-support-cards .function-card-list-item:nth-child(3)', '.l-com-function-support-cards .function-card-list-item:nth-child(3)', 0.6, 0.5)

    fadeIn('.banner', '.banner', 0, 1.2, rotateY)

    fadeIn('.l-com-ltv', '.l-com-ltv', 0, 2)
  }

    // efo page
    const efo = document.querySelector('.l-efo')
    if (efo) {
      leftToRightForInset('.mv-title', '.mv-title', 0, 1)
      fadeIn('.mv-desc', '.mv-desc', 0.2, 1.2)
      fadeIn('.mv-image', '.mv-image', 0.4, 1.2)

      leftToRightForPolygon('.why-title', '.why-title', 0, 1)
      fadeIn('.why-item:first-child', '.why-item:first-child', 0.4, 1.2)
      fadeIn('.why-item:nth-child(2)', '.why-item:nth-child(2)', 0.4, 1.2)
      fadeIn('.why-card-image-wrapper', '.why-card-image-wrapper', 0.6, 1.2)
      leftToRightForInset('.why-banner ', '.why-banner ', 0, 1)  
  
      fadeIn('.merit-title', '.merit-title', 0, 1, {
        from: {
          rotationY: 90,
        },
        to: {
          rotationY: 0
        }
      })
      fadeIn('.merit-item:first-child', '.merit-item:first-child', 0.2, 1.2)
      fadeIn('.merit-item:nth-child(2)', '.merit-item:nth-child(2)', 0.2, 1.2)
      fadeIn('.merit-item:nth-child(3)', '.merit-item:nth-child(3)', 0.2, 1.2)
   
      leftToRightForInset('.function-title', '.function-title', 0, 1)

      const rotateY = {
        from: {
          rotationY: 90,
        },
        to: {
          rotationY: 0
        }
      }
      fadeIn('.function-item:first-child', '.function-item:first-child', 0.2, 1, rotateY)
      fadeIn('.function-item:nth-child(2)', '.function-item:nth-child(2)', 0.3, 1, rotateY)
      fadeIn('.function-item:nth-child(3)', '.function-item:nth-child(3)', 0.4, 1, rotateY)
      fadeIn('.function-item:nth-child(4)', '.function-item:nth-child(4)', 0.5, 1, rotateY)
      fadeIn('.function-item:nth-child(5)', '.function-item:nth-child(5)', 0.6, 1, rotateY)
      fadeIn('.function-item:nth-child(6)', '.function-item:nth-child(6)', 0.7, 1, rotateY)
      fadeIn('.function-item:nth-child(7)', '.function-item:nth-child(7)', 0.8, 1, rotateY)
      fadeIn('.function-item:nth-child(8)', '.function-item:nth-child(8)', 0.9, 1, rotateY)
  
      leftToRightForInset('.l-efo-ab-test-title', '.l-efo-ab-test-title', 0.4, 1)
      fadeIn('.l-efo-ab-test-card .efo-function-card ', '.l-efo-ab-test-card .efo-function-card ', 0, 1.4)

      leftToRightForInset('.l-efo-sms-title', '.l-efo-sms-title', 0.4, 1)
      fadeIn('.l-efo-sms-card .efo-function-card', '.l-efo-sms-card .efo-function-card', 0, 1.4)

      leftToRightForInset('.l-efo-conversion-api-title', '.l-efo-conversion-api-title', 0.4, 1)
      fadeIn('.l-efo-conversion-api-card .efo-function-card', '.l-efo-conversion-api-card .efo-function-card', 0, 1.4)

      leftToRightForInset('.l-efo-keypad-title', '.l-efo-keypad-title', 0.4, 1)
      fadeIn('.l-efo-keypad-card .efo-function-card', '.l-efo-keypad-card .efo-function-card', 0, 1.4)

      leftToRightForInset('.l-efo-restore-title', '.l-efo-restore-title', 0.4, 1)
      fadeIn('.l-efo-restore-card .efo-function-card', '.l-efo-restore-card .efo-function-card', 0, 1.4)

      leftToRightForInset('.l-efo-dashboard-title', '.l-efo-dashboard-title', 0.4, 1)
      fadeIn('.l-efo-dashboard-card .efo-function-card', '.l-efo-dashboard-card .efo-function-card', 0, 1.4)

      leftToRightForInset('.l-efo-data-title', '.l-efo-data-title', 0.4, 1)
      fadeIn('.l-efo-data-item:first-child', '.l-efo-data-item:first-child', 0, 1, rotateY)
      fadeIn('.l-efo-data-item:nth-child(2)', '.l-efo-data-item:nth-child(2)', 0.4, 1, rotateY)

      fadeIn('.banner', '.banner', 0, 1.2, rotateY)
      fadeIn('.l-efo-cvr', '.l-efo-cvr', 0, 2)
 
    }  

}

function fadeIn(item, triggerItem, delay, duration, additionalOptions = {}) {
  gsap.fromTo(item, {
    opacity: 0,
    ...additionalOptions.from,
  },{
    scrollTrigger: triggerItem,
    opacity: 1,
    delay: delay,
    duration: duration,
    ease: 'power1.out',
    ...additionalOptions.to,
  });
}

function leftToRightForPolygon(item, triggerItem, delay, duration, additionalOptions = {}) {
  gsap.fromTo(item, {
    opacity: 0,
    ...additionalOptions.from,
  },{
    scrollTrigger: triggerItem,
    opacity: 1,
    delay: delay,
    duration: duration,
    clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
    ease: 'power1.out',
    ...additionalOptions.to,
  });
}

function leftToRightForInset(item, triggerItem, delay, duration, additionalOptions = {}) {
  gsap.fromTo(item, {
    opacity: 0,
    clipPath: "inset(0 100% 0 0)",
    ...additionalOptions.from,
  },{
    scrollTrigger: triggerItem,
    opacity: 1,
    delay: delay,
    duration: duration,
    clipPath: "inset(0 0% 0 0)",
    ease: 'power1.out',
    ...additionalOptions.to,
  });
}

if (!NodeList.prototype.forEach)
  NodeList.prototype.forEach = Array.prototype.forEach;

import animation from "./components/animation";
import graphAnimation from "./components/graph-animation";
import menu from "./components/menu";
import sceneIframe from "./components/scene-iframe";
import SmoothScroll from "./components/smooth-scroll";

animation();
graphAnimation();
menu();
sceneIframe();
document.querySelectorAll(".js-scroll").forEach((e) => new SmoothScroll(e));
